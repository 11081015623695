window.jQuery = window.$ = require('jquery');

require('bootstrap');
require('jquery-colorbox');
require('slick-carousel');
import videojs from 'video.js';
window.videojs = videojs;


$(function() {
	function toggleNav(){
	  var chkbox = document.getElementById("mobile-toggle");
	  chkbox.checked = !chkbox.checked;
      //console.log('rest');
        if(chkbox.checked) $('.mobile-toggle').addClass('opening');
        else $('.mobile-toggle').removeClass('opening');
	}
	var nav = document.getElementById('main-nav');
    nav.addEventListener('click', toggleNav);

});


$('.responsive-video').each(function(){
    var id = Math.random().toString(36).substr(2, 9);
    $(this).attr('id', 'video-' + id);
    $(this).addClass('video-js')
    videojs("video-" + id, {
        techOrder: ['html5'],
        autoplay: false,
        width: 640,
        height: 480,
        controls: true,
        fluid: true
    });
});

$('a.display-image').colorbox({maxWidth:'95%',maxHeight:'95%'});

import AOS from 'aos';
AOS.init();

$('.crafty-block-slideshow').slick({
    dots: true,
    arrows: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 600
});

$('.home-brands').slick({
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 300,
    rows: 2,
    slidesToShow: 7,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
      ]
});
